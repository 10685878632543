lib-quick-search {
    .button__condition {
        @apply border-b-4 border-transparent pt-4 text-14 leading-27 transition-colors lg:text-18;

        &--active {
            @apply font-medium;

            &.light {
                @apply border-accent text-accent;
            }

            &.dark {
                @apply border-white;
            }
        }
    }
}
